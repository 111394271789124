import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-xs transition-colors border-solid border disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-button-primary-bg border-button-primary-border text-button-primary-fg shadow hover:bg-button-primary-bg-hover hover:border-button-primary-border-hover",
        primaryPlain:
          "bg-transparent border-none text-brand-600 hover:bg-gray-100 border-none",
        destructive:
          "bg-error-600 border-error-600 text-white hover:bg-error-700 hover:border-error-700",
        outline:
          "text-gray-700 bg-white hover:bg-gray-50 border-solid border border-gray-300",
        secondaryGray:
          "bg-button-secondary-gray-bg border-button-secondary-gray-border text-button-secondary-gray-fg shadow hover:bg-button-secondary-gray-bg-hover hover:border-button-secondary-gray-border-hover hover:text-button-secondary-gray-fg-hover",
        secondaryColor:
          "bg-button-secondary-color-bg border-button-secondary-color-border text-button-secondary-color-fg shadow hover:bg-button-secondary-color-bg-hover hover:border-button-secondary-color-border-hover hover:text-button-secondary-gray-fg-hover",
        tertiaryGray:
          "bg-button-tertiary-gray-bg border-button-tertiary-gray-border text-button-tertiary-gray-fg hover:bg-button-tertiary-gray-bg-hover hover:border-button-tertiary-gray-border-hover hover:text-button-tertiary-gray-fg-hover",
        link: "text-primary underline-offset-4 hover:underline",
        listItem:
          "w-full flex select-none border-none justify-start bg-transparent data-[disabled=true]:pointer-events-none hover:bg-accent hover:text-accent-foreground data-[selected=true]:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-12 rounded-xs px-xl py-[10px] text-md-semibold min-w-[78px]",
        icon: "flex items-center p-4",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
