import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

// Create a custom merge function if needed
const customTwMerge = extendTailwindMerge({
  extend: {
    // Define custom class groups to ensure they conflict properly
    classGroups: {
      // This ensures the custom font size classes are treated correctly
      "font-size": [
        {
          text: [
            "xs-regular",
            "xs-medium",
            "xs-semibold",
            "xs-bold",
            "sm-regular",
            "sm-medium",
            "sm-semibold",
            "sm-bold",
            "md-regular",
            "md-medium",
            "md-semibold",
            "md-bold",
            "lg-regular",
            "lg-medium",
            "lg-semibold",
            "lg-bold",
            "xl-regular",
            "xl-medium",
            "xl-semibold",
            "xl-bold",
            "2xl-regular",
            "2xl-medium",
            "2xl-semibold",
            "2xl-bold",
            "display-xs-regular",
            "display-xs-medium",
            "display-xs-semibold",
            "display-xs-bold",
            "display-sm-regular",
            "display-sm-medium",
            "display-sm-semibold",
            "display-sm-bold",
            "display-md-regular",
            "display-md-medium",
            "display-md-semibold",
            "display-md-bold",
            "display-lg-regular",
            "display-lg-medium",
            "display-lg-semibold",
            "display-lg-bold",
            "display-xl-regular",
            "display-xl-medium",
            "display-xl-semibold",
            "display-xl-bold",
            "display-2xl-regular",
            "display-2xl-medium",
            "display-2xl-semibold",
            "display-2xl-bold",
          ],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
